import {IonButton} from '@ionic/react';
import React from 'react';
import {useDispatch} from 'react-redux';
import {signOut} from "../reducers/authenticatedSlice";
import {AppDispatch} from "../store";
import {useHistory} from "react-router";

// This should reflect the URL added earlier to your "Allowed Logout URLs" setting
// in the Auth0 dashboard.
// const logoutUri = 'quantfury.access.manager://dev-a3kvr2ho.us.auth0.com/capacitor/quantfury.access.manager/callback';

const LogoutButton: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const history = useHistory();

    return <IonButton onClick={async () => {
        dispatch(signOut())
        setTimeout(() => {
            history.goBack();
        });
    }}>Log out</IonButton>;
};

export default LogoutButton;
