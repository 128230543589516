import {
    IonContent,
    IonLabel,
    IonNote
} from '@ionic/react';
import {Component} from 'react';
import './Users.css';
import {connect} from "react-redux";
import LogoutButton from "../components/LogoutButton";
import {RootState} from "../store";
import {User} from "firebase/auth";

interface ProfileProps {
    user: User | null;
}

class Profile extends Component<ProfileProps> {

    render() {
        if (!this.props.user) {
            return <></>
        }

        return (
            <IonContent>
                <div className="profile-overview">
                    <div className={"profile-picture"}>
                        <img src={this.props.user.photoURL ?? ''} alt={this.props.user.email!} />
                    </div>
                    <IonLabel>{this.props.user.displayName}</IonLabel>
                    <IonNote>{this.props.user.email}</IonNote>
                    <LogoutButton />
                </div>
            </IonContent>
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    user: state.authenticated.googleUser,
});

export default connect(mapStateToProps)(Profile);
