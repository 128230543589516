import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import axios, {AxiosError} from "axios";
import {getIdToken} from "firebase/auth";
import {auth, logout} from "../firebase";
import {User} from "firebase/auth";
import {clearToken, getToken, setToken} from "../repositories/TokenRepository";
import moment from "moment";

export interface AuthenticatedState {
    totpLoginNeeded: boolean,
    totpUri: string | null,
    value: boolean,
    error: string | null,
    googleUser: User | null,
    countBack: number | null,
}

const initialState: AuthenticatedState = {
    totpLoginNeeded: false,
    totpUri: null,
    value: false,
    error: null,
    googleUser: null,
    countBack: null,
}

export const initiateAuthenticateTotp = createAsyncThunk('auth/initiateAuthenticateTotp', async (user: User, {dispatch}) => {
    const token = await getIdToken(user!);
    while (true) {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL! + '/authentication/totp',
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            if (response.data.registrationNeeded) {
                await dispatch(setTotpUri(response.data.totpUri));
            } else {
                await dispatch(setTotpLoginNeeded(true));
            }
            break;
        } catch (e: any) {
            console.log(e);
            if (typeof e.response !== 'undefined' && (e.response.status === 401 || e.response.status === 403)) {
                dispatch(signOut());
                break;
            }
            await dispatch(setAuthenticationError(e.message));
        }
        await new Promise(resolve => setTimeout(resolve, 5000));
    }
});

const countBackAuthentication = createAsyncThunk('auth/countBackAuthentication', async (_, {dispatch}) => {
    dispatch(setCountBack(moment().unix()));
    await new Promise(f => setTimeout(f, 10000));
    dispatch(setCountBack(null));
});

export const authenticateTotp = createAsyncThunk('auth/authenticateTotp', async (authCode: string, {dispatch, rejectWithValue}) => {
    if (!auth.currentUser) {
        throw new Error('User is null');
    }

    let token: any;
    try {
        token = await auth.currentUser.getIdToken();
    } catch (e) {
        console.log(e);
    }

    try {
        const result = await axios.post(process.env.REACT_APP_API_URL! + '/authentication', {
                authCode
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        console.log(result);

        setToken(result.data.accessToken);

        await dispatch(setTotpLoginNeeded(false));
        await dispatch(setTotpUri(null));
    } catch (e) {
        if ((e as AxiosError).response?.status === 429) {
            dispatch(countBackAuthentication());
        }
        return rejectWithValue(e);
    }
});


export const authenticate = createAsyncThunk('auth/authenticate', async (user: User, {dispatch}) => {
    await dispatch(setGoogleUser(JSON.parse(JSON.stringify(user))));

    if (user == null) {
        throw new Error('User is null');
    }

    if (getToken() != null) {
        return true;
    }

    await dispatch(initiateAuthenticateTotp(user));
})

export const signOut = createAsyncThunk('auth/signOut', async (_, {rejectWithValue}) => {
    clearToken();
    try {
        await logout()
        return await axios.delete(process.env.REACT_APP_API_URL! + '/authentication/signOut')
    } catch (e) {
        return rejectWithValue(e);
    }
})

export const authenticatedSlice = createSlice({
    name: 'authenticated',
    initialState,
    reducers: {
        setGoogleUser: (state, action: PayloadAction<User>) => {
            state.googleUser = action.payload;
        },
        setTotpLoginNeeded: (state, action: PayloadAction<boolean>) => {
            state.totpLoginNeeded = action.payload;
        },
        setTotpUri: (state, action: PayloadAction<string | null>) => {
            state.totpUri = action.payload;
        },
        setAuthenticationError: (state, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
        setCountBack: (state, action: PayloadAction<number | null>) => {
            state.countBack = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(authenticateTotp.fulfilled, (state, action) => {
            state.value = true;
        })
        builder.addCase(authenticate.fulfilled, (state, action) => {
            if (action.payload) {
                state.value = true;
                state.error = null;
            }
        })
        builder.addCase(authenticateTotp.rejected, (state, action) => {
            console.log(action);
            state.value = false;
            state.error = action.error.message ?? null;
        })
        builder.addCase(initiateAuthenticateTotp.rejected, (state, action) => {
            console.log(action);
            state.value = false;
            state.error = action.error.message ?? null;
        })
        builder.addCase(signOut.fulfilled, (state, action) => {
            state.value = false;
            state.error = null;
        })
        builder.addCase(signOut.rejected, (state, action) => {
            state.value = false;
        })
    }
})

// Action creators are generated for each case reducer function
export const { setCountBack, setGoogleUser, setTotpLoginNeeded, setTotpUri, setAuthenticationError } = authenticatedSlice.actions

export default authenticatedSlice.reducer
