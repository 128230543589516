import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface WorkerSliceState {
    serviceWorkerInitialized: boolean,
    serviceWorkerUpdated: boolean,
    serviceWorkerRegistration: null | ServiceWorkerRegistration,
}

const initialState: WorkerSliceState = {
    serviceWorkerInitialized: false,
    serviceWorkerUpdated: false,
    serviceWorkerRegistration: null,
}

const workerSlice = createSlice({
    name: 'worker',
    initialState,
    reducers: {
        serviceWorkerInitAction: (state, action: PayloadAction<ServiceWorkerRegistration>) => {
            state.serviceWorkerInitialized = true;
            state.serviceWorkerRegistration = action.payload;
        },
        serviceWorkerUpdateAction: (state, action: PayloadAction<ServiceWorkerRegistration>) => {
            state.serviceWorkerUpdated = true;
            state.serviceWorkerRegistration = action.payload;
        },

    }
})

export const { serviceWorkerInitAction, serviceWorkerUpdateAction } = workerSlice.actions


export default workerSlice.reducer
