import { configureStore } from '@reduxjs/toolkit'

import authenticatedReducer from './reducers/authenticatedSlice'
import userReducer from './reducers/userSlice'
import usersReducer from './reducers/usersSlice'
import requestStatusReducer from './reducers/requestStatusSlice'
import accessesReducer from './reducers/accessesSlice'
import workerReducer from './reducers/workerSlice'

export const store = configureStore({
    reducer: {
        authenticated: authenticatedReducer,
        user: userReducer,
        users: usersReducer,
        requestStatus: requestStatusReducer,
        accesses: accessesReducer,
        worker: workerReducer,
    }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
