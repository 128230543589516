import React, {useState} from "react";
import {
    IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle,
    IonInput, IonItem,
    IonList,
    useIonToast
} from "@ionic/react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../store";
import {authenticateTotp} from "../reducers/authenticatedSlice";
import {QRCodeSVG} from "qrcode.react";

export const TotpRegister: React.FC = () => {
    const [present] = useIonToast();
    const dispatch = useDispatch<AppDispatch>();
    const [authCode, setAuthCode] = useState('')

    const totpUri = useSelector((state: RootState) => state.authenticated.totpUri);

    return <div className={"vertical-center"}>
        <IonList lines={"none"} className={"ion-text-center"}>
            <IonItem style={{'textAlign': 'center'}}>
                <IonCard>
                    <QRCodeSVG value={totpUri!} />
                    <IonCardHeader>
                        <IonCardTitle>Two factor authentication</IonCardTitle>
                        {/*<IonCardSubtitle>Card Subtitle</IonCardSubtitle>*/}
                    </IonCardHeader>

                    <IonCardContent>
                        Scan this QR code using Google Authenticator or Authy app on your phone.
                    </IonCardContent>
                </IonCard>
            </IonItem>
            <IonItem lines={"inset"}>
                <IonInput autofocus style={{'textAlign': 'center'}} type="text" value={authCode} onIonChange={(e) => {
                    setAuthCode(e.detail.value!);
                }} placeholder="Enter code" />
            </IonItem>
            <IonButton className={"ion-margin-top"} color="primary" onClick={async () => {
                dispatch(authenticateTotp(authCode)).unwrap().catch(() => {
                    console.log("error");
                    present({
                        message: "Invalid code",
                        color: "danger",
                        duration: 3000,
                    })
                });
            }}>Register</IonButton>
        </IonList>
    </div>

};
