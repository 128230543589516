import {
    IonAvatar, IonContent,
    IonItem, IonLabel,
    IonList, IonRefresher, IonRefresherContent, RefresherEventDetail
} from '@ionic/react';
import {Component} from 'react';
import './Users.css';
import {User} from "../entities/User";
import {connect} from "react-redux";
import {fetchUsers} from "../reducers/usersSlice";

interface UsersProps {
    fetchUsers: any
    users: User[]
}

class Users extends Component<UsersProps> {

    interval: NodeJS.Timeout | null = null;

    async componentDidMount() {
        // setTimeout(,100)
        try {
            await this.props.fetchUsers().unwrap();
        } catch (e) {
            console.error(e);
            this.interval = setInterval(async () => {
                try {
                    await this.props.fetchUsers().unwrap();
                    if (this.interval != null) {
                        clearInterval(this.interval);
                        this.interval = null;
                    }
                } catch (e) {
                    console.log(e);
                }
            }, 5000);
        }
    }

    componentWillUnmount() {
        console.log('user component unmount');
        if (this.interval != null) {
            clearInterval(this.interval);
            this.interval = null;
        }
    }

    async refresh(event: CustomEvent<RefresherEventDetail>) {
        this.props.fetchUsers();
        await event.detail.complete();
    }

    render() {
        return (
            <IonContent>
                <IonRefresher slot="fixed" onIonRefresh={this.refresh.bind(this)}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                <IonList>
                    {this.props.users.map((user: any) =>
                        (<IonItem href={"#/users/" + user.id} button detail={true} key={user.id}>
                            <IonAvatar slot={"start"}>
                                <img alt="Silhouette of a person's head" src="https://ionicframework.com/docs/img/demos/avatar.svg" />
                            </IonAvatar>
                            <IonLabel>
                                <h3>{user.name}</h3>
                                <p>{user.email}</p>
                            </IonLabel>
                        </IonItem>)
                    )}
                </IonList>
            </IonContent>
        )
    }
}

const mapStateToProps = (state: any) => ({
    users: state.users
});

const mapDispatchToProps = {fetchUsers};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
