import {Component} from "react";
import {
    IonAvatar,
    IonContent,
    IonItem, IonItemDivider, IonLabel
} from "@ionic/react";
import AccessList from "../components/AccessList";
import {clearUser, fetchUser} from "../reducers/userSlice";
import {connect} from "react-redux";
import {User} from "../entities/User";

interface ViewUserProperties {
    userId: number;
    user: User | null;
    clearUser: any;
    fetchUser: any;
}

class ViewUser extends Component<ViewUserProperties> {

    constructor(props: ViewUserProperties) {
        super(props);

        this.props.clearUser();
    }

    async componentDidMount() {
        try {
            await this.props.fetchUser(this.props.userId).unwrap();
        } catch (e) {
            console.log('error first fetchUser', e, (window as any).fetchUserInterval);
            (window as any).fetchUserInterval = setInterval(async () => {
                try {
                    await this.props.fetchUser(this.props.userId).unwrap();
                    // console.log('this.interval', (window as any).fetchUserInterval);
                    if ((window as any).fetchUserInterval != null) {
                        clearInterval((window as any).fetchUserInterval);
                        (window as any).fetchUserInterval = null;
                    }
                } catch (e) {
                    console.log('fetchUser error', e, (window as any).fetchUserInterval);
                }
            }, 5000);
        }
    }

    componentWillUnmount() {
        console.log('componentWillUnmount', (window as any).fetchUserInterval);
        if ((window as any).fetchUserInterval != null) {
            clearInterval((window as any).fetchUserInterval);
            (window as any).fetchUserInterval = null;
        }
    }

    render() {
        if (this.props.user === null) {
            return '';
        }
        return (
            <IonContent>
                <IonItem lines="none">
                    <div className="ion-margin-top center-elements">
                        <IonAvatar>
                            <img alt="Silhouette of a person's head" src="https://ionicframework.com/docs/img/demos/avatar.svg" />
                        </IonAvatar>
                    </div>
                </IonItem>
                <IonItem lines="none" className="ion-text-center">
                    <IonLabel>
                        <h3>{this.props.user.name}</h3>
                        <p>{this.props.user.email}</p>
                    </IonLabel>
                </IonItem>
                <IonItemDivider></IonItemDivider>
                <AccessList />
                {/*<IonItem lines="none"><IonText color="medium" className="ion-text-center">User has no accesses managed by you</IonText></IonItem>*/}
            </IonContent>
        )
    }
}

const mapStateToProps = (state: any) => ({
    user: state.user
});

const mapDispatchToProps = {fetchUser, clearUser};

export default connect(mapStateToProps, mapDispatchToProps)(ViewUser);
