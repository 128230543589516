import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {User} from "../entities/User";
import {getToken} from "../repositories/TokenRepository";

const initialState: User[] = [];

export const fetchUsers = createAsyncThunk('users/fetchUsers', async (_, {rejectWithValue}) => {
    try {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/user', {
            headers: {
                'Api-Key': `Bearer ${getToken()}`
            }
        });
        return response.data;
    } catch (error) {
        return (rejectWithValue as any)(error);
        // throw error;
    }
})

const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchUsers.fulfilled, (state, action) => {
            return action.payload
        })
    }
})

export default userSlice.reducer
