// import './trusted-security-policies'

import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import './firebase'

import { Provider } from 'react-redux'
import { store } from './store'
import {serviceWorkerInitAction, serviceWorkerUpdateAction} from "./reducers/workerSlice";

// axios.defaults.withCredentials = true;

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    // <React.StrictMode>
        <Provider store={store}>
            <App/>
        </Provider>
    // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register({
    onSuccess: registration => {
        console.log('onSuccess', registration);
        store.dispatch(serviceWorkerInitAction(registration))
    },
    onUpdate: registration => {
        console.log('onUpdate', registration);
        store.dispatch(serviceWorkerUpdateAction(registration))
    }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
