import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useIonToast} from "@ionic/react";
import {signOut} from "../reducers/authenticatedSlice";
import {AppDispatch} from "../store";

export const LogoutToast: React.FC = () => {
    const logoutIsNeeded = useSelector((state: any) => state.requestStatus.logoutIsNeeded);
    const [present] = useIonToast();
    const dispatch = useDispatch<AppDispatch>();

    if (logoutIsNeeded) {
        present({
            message: "Logging out...",
            duration: 3,
            onDidDismiss: async () => {
                await dispatch(signOut());
            }
        })
    }

    return <></>
};
