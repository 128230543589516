import {Component} from "react";
import {
    IonItem, IonLabel, IonList, IonRefresher, IonRefresherContent, IonToggle, RefresherEventDetail
} from "@ionic/react";
import {Access} from "../entities/Access";
import {User} from "../entities/User";
import {connect} from "react-redux";
import {clearAccesses, fetchAccesses, patchAccess} from "../reducers/accessesSlice";

interface AccessListProperties {
    user: User,
    accesses: Access[],
    fetchAccesses: any;
    patchAccess: any;
    clearAccesses: any;
}

class AccessList extends Component<AccessListProperties> {
    constructor(props: AccessListProperties) {
        super(props);

        this.props.clearAccesses();
    }

    componentDidMount() {
        if (this.props.user != null) {
            this.props.fetchAccesses(this.props.user.id);
        }
    }

    async refresh(event: CustomEvent<RefresherEventDetail>) {
        this.props.fetchAccesses(this.props.user.id);
        event.detail.complete();
    }

    entityInfoTemplate(access: Access) {
        // if (this.props.showUserInfo) {
        //     return (<>
        //         <IonAvatar slot={"start"}>
        //             <img alt="Silhouette of a person's head"
        //                  src="https://ionicframework.com/docs/img/demos/avatar.svg"/>
        //         </IonAvatar>
        //         <IonLabel>
        //             <h3>{access.user?.name}</h3>
        //             <p>{access.user?.email}</p>
        //         </IonLabel>
        //     </>)
        // } else {
            return (
                <IonLabel>
                    <h3>{access.service?.serviceName}</h3>
                    <p>{access.service?.serviceAdditionalInformation}</p>
                </IonLabel>)
        // }
    }

    render() {
        if (this.props.user == null) {
            return '';
        }

        return (
            <>
                <IonRefresher slot="fixed" onIonRefresh={this.refresh.bind(this)}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                <IonList>
                    {/*<IonItem lines="none">*/}
                    {/*    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>*/}
                    {/*        <IonButton onClick={() => this.setState({isCreateModalOpen: true})}>Add</IonButton>*/}
                    {/*    </div>*/}
                    {/*</IonItem>*/}

                    {this.props.accesses.length === 0 ? <IonItem lines="none" className={"ion-text-center"}><IonLabel className={"ion-color-secondary"}>No accesses available</IonLabel></IonItem> : ''}

                    {this.props.accesses.map((access, index) => (
                        <IonItem lines={index === this.props.accesses.length - 1 ? "none" : "inset"} button
                                 onClick={() => this.setState({accessIndex: index})} key={index}>
                            {this.entityInfoTemplate(access)}
                            <IonToggle slot="end" checked={access.enabled} onClick={(e) => e.stopPropagation()}
                                       onIonChange={async (e) => {
                                           try {
                                               await this.props.patchAccess({
                                                   userId: this.props.user.id,
                                                   serviceId: access.serviceId,
                                                   applyPatch: (access: Access) => {
                                                       access.enabled = e.detail.checked;
                                                   }
                                               }).unwrap();
                                           } catch (error) {
                                               e.target.checked = !e.detail.checked;
                                           }
                                       }} />
                        </IonItem>
                    ))}
                </IonList>
                {/*<AccessEditModal*/}
                {/*    access={(this.state.accessIndex == null ? null : this.props.accesses[this.state.accessIndex])}*/}
                {/*    updateAccess={(access) => {*/}
                {/*        this.props.updateAccesses(produce(this.props.accesses, draft => {*/}
                {/*            draft[this.state.accessIndex!] = access;*/}
                {/*        }));*/}
                {/*    }}*/}
                {/*    saveAccess={async (callback) => {*/}
                {/*        await this.saveAccess(this.state.accessIndex!, callback);*/}
                {/*    }}*/}
                {/*    onDidDismiss={() => this.setState({accessIndex: null})}*/}
                {/*/>*/}
                {/*<AccessCreateModal isOpen={this.state.isCreateModalOpen}*/}
                {/*                   addAccess={this.props.addAccess}*/}
                {/*                   setAccessDefaults={this.props.setNewAccessDefaults}*/}
                {/*                   onDidDismiss={() => this.setState({isCreateModalOpen: false})}*/}
                {/*/>*/}
            </>
        )
    }
}

const mapStateToProps = (state: any) => ({
    user: state.user,
    accesses: state.accesses
});

const mapDispatchToProps = {patchAccess, fetchAccesses, clearAccesses};

export default connect(mapStateToProps, mapDispatchToProps)(AccessList);
