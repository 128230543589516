import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit'
import {User} from "../entities/User";
import axios from "axios";
import {getToken} from "../repositories/TokenRepository";

const initialState: User | null = null;

export const fetchUser = createAsyncThunk('user/fetchUser', async (id, {rejectWithValue}) => {
    try {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/user/' + id, {
            headers: {
                'Api-Key': `Bearer ${getToken()}`
            }
        });
        return response.data;
    } catch (error) {
        return (rejectWithValue as any)(error);
    }
})

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        clearUser: (state, action: PayloadAction<boolean>) => {
            return null;
        },

    },
    extraReducers(builder) {
        builder.addCase(fetchUser.fulfilled, (state, action) => {
            return action.payload
        })
    },
})

export const { clearUser } = userSlice.actions


export default userSlice.reducer
