import {
    IonAvatar,
    IonChip, IonLabel
} from "@ionic/react";

import './ProfileButton.css';
import {useHistory} from "react-router";
import {useSelector} from "react-redux";
import {RootState} from "../store";

function navigateToProfile(history: any) {
    history.push('/profile')
}

const ProfileButton: React.FC = props => {
    const user = useSelector((state: RootState) => state.authenticated.googleUser)
    const history = useHistory()

    if (!user) return null;

    return (
        <>
            <IonChip onClick={e => {
                e.preventDefault()
                navigateToProfile(history);
            }}>
                <IonAvatar onClick={e => {
                    e.preventDefault();
                    navigateToProfile(history);
                }}>
                    <img src={user.photoURL ?? ''} alt={user.email!}/>
                </IonAvatar>
                <IonLabel>{user.displayName!.split(' ')[0]}</IonLabel>
            </IonChip>
        </>
    );
};

export default ProfileButton;
