import {initializeApp} from 'firebase/app'
import { initializeAnalytics } from 'firebase/analytics'
import { GoogleAuthProvider, getAuth, signOut, signInWithPopup } from 'firebase/auth';

const config = {
    apiKey: "AIzaSyDIUKgH2rfDsVH43A9XWcojGKcOaEUQCi0",
    authDomain: "jitaccessmanager.firebaseapp.com",
    projectId: "jitaccessmanager",
    storageBucket: "jitaccessmanager.appspot.com",
    messagingSenderId: "171051473608",
    appId: "1:171051473608:web:a2fb6ffa6cced8e23763fd",
    measurementId: "G-W43VK8LF06"
}

const app = initializeApp(config);
const auth = getAuth(app);

initializeAnalytics(app);

// firebase.auth()

const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
    try {
        // await signInWithRedirect(auth, googleProvider);
        await signInWithPopup(auth, googleProvider);
        // const user = res.user;
        // console.log(user)
        // const q = query(collection(db, "users"), where("uid", "==", user.uid));
        // const docs = await getDocs(q);
        // if (docs.docs.length === 0) {
        //     await addDoc(collection(db, "users"), {
        //         uid: user.uid,
        //         name: user.displayName,
        //         authProvider: "google",
        //         email: user.email,
        //     });
        // }
    } catch (err) {
        console.error(err);
        // alert(err.message);
    }
};

const logout = () => {
    return signOut(auth);
};

export {
    auth,
    signInWithGoogle,
    logout,
};
